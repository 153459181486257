@import "colors";
@import '../../node_modules/bootstrap/scss/bootstrap';
@import "global";
@import "spinner";
@import "scrollbar";

.button-icon-image {
  width: 40px;
  height: 40px;
}

.sidebar{
  width: 400px;
  min-width: 400px;
  max-width: 0.5vw !important;
}

.card-header{
  border: 0;
  background-color: $white;
}

.btn{
  border-radius: 15px;
}

.dropdown-toggle::after {
  display: none !important;
}